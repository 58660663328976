import { 
  egg, 
  person, 
  school
} from 'ionicons/icons';
import {
  collegeAccess,
  mentalHealth,
  reproductiveHealth,
} from '../content.json'

const getIcon: { [key: string]: string } = {
  [mentalHealth.title]: person,
  [reproductiveHealth.title]: egg,
  [collegeAccess.title]: school,
}

export default getIcon
