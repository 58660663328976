import React, { useState } from 'react';
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  callOutline,
  linkOutline,
} from 'ionicons/icons';
import AppHeader from '../components/AppHeader';
import TabSearch from '../components/TabSearch';
import Topic from '../types'

import '../theme/topics.css';
import './TopicTab.css';


const BrowseTab: React.FC<{ topic: Topic }> = ({ topic }) => {
  const [resources, setResources] = useState(topic.resources)

  return (
    <IonPage>
      <AppHeader topic={topic} />
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{topic.title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <TabSearch resources={topic.resources} setResources={setResources} />

        {resources.map(resource => (
          <IonCard key={resource.title} className={topic.key}>
            <IonItem href={resource.url} target="_blank" className={topic.key}>
              <IonIcon icon={resource.url && linkOutline} slot="start" />
              <IonLabel class='ion-text-wrap'>
                <b>{resource.title}</b>
              </IonLabel>
            </IonItem>

            {resource.hotline
            ? <IonItem href={resource.hotline.link} className={topic.key}>
              <IonIcon icon={callOutline} slot="start" />
              <IonLabel>
                <b>{resource.hotline.label}</b>
              </IonLabel>
            </IonItem>
            : null}

            <IonCardContent style={{ whiteSpace: 'pre-line'}}>
              {resource.description}
            </IonCardContent>
          </IonCard>
        ))}
      
      </IonContent>
    </IonPage>
  );
};

export default BrowseTab;
