import React, { useState } from 'react';
import { IonApp } from '@ionic/react';
import AppMenu from './components/AppMenu'
import AppTabs from './components/AppTabs'
import Intro from './pages/Intro'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

const largeScreenStyle = { maxWidth: '414px', margin: 'auto' }

const App: React.FC = () => {
  const [ showIntro, setShowIntro ] = useState(true)
  return (
    <IonApp style={largeScreenStyle}>
      {showIntro && !localStorage.getItem('intro')
        ? <Intro setShowIntro={setShowIntro} />
        : <>
          <AppMenu />
          <AppTabs />
        </>
      }
    </IonApp>
  )
};

export default App;
