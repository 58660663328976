import React from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import AppHeader from '../components/AppHeader';
import Topic from '../types'
import { createPath } from '../utils';

import './TopicTab.css';


const TopicTab: React.FC<{ topic: Topic }> = ({ topic }) => {
  return (
    <IonPage>
      <AppHeader topic={topic} />
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{topic.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <iframe  
          frameBorder={0}
          title={topic.youTubeId}
          src={`https://www.youtube.com/embed/${topic.youTubeId}`}
          width={'100%'}
          height={'210'}
        ></iframe>

        <IonGrid>
          <IonRow>
            <IonCol style={{
              margin: '3em 0',
              lineHeight: '1.5',
            }}>{topic.description}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                routerLink={`${createPath(topic)}/quiz`}
                expand='block' fill='outline'
              >Take Quiz</IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                className={topic.key}
                routerLink={createPath(topic)}
                expand='block'
              >Browse Resources</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      
      </IonContent>
    </IonPage>
  );
};

export default TopicTab;
