import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import NotFound from '../pages/NotFound';
import BrowseTab from '../pages/BrowseTab';
import TopicTab from '../pages/TopicTab';
import QuizTab from '../pages/QuizTab';
import getIcon from '../theme/icons'
import {
  collegeAccess,
  mentalHealth,
  reproductiveHealth,
} from '../content.json'
import { createPath } from '../utils';

const topics = [mentalHealth, reproductiveHealth, collegeAccess]

const AppTabs: React.FC = () => (
  <IonReactRouter>
    <IonTabs>
      <IonRouterOutlet id='menuContentForDragEvents'>
        {topics.map((topic, index) => 
          <Route path={`${createPath(topic)}/intro`} exact={true} key={index} render={(props) =>
            <TopicTab {...props} topic={topic} />
          } />
        )}
        {topics.map((topic, index) => 
          <Route path={createPath(topic)} exact={true} key={index} render={(props) =>
            <BrowseTab {...props} topic={topic} />
          } />
        )}
        {topics.map((topic, index) => 
          <Route path={`${createPath(topic)}/quiz`} exact={true} key={index} render={(props) => 
            <QuizTab {...props} topic={topic} />
          } />
        )}
        <Route path="/" render={() =>
          <Redirect to={createPath(mentalHealth)} />} exact={true}
        />
        <Route component={NotFound} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        {topics.map((topic, index) =>
          <IonTabButton tab={topic.title} href={createPath(topic)} key={index}>
            <IonIcon icon={getIcon[topic.title]} />
            <IonLabel>{topic.title}</IonLabel>
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  </IonReactRouter>
);

export default AppTabs;
