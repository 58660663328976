import React from 'react';
import {
  IonButton,
  IonContent,
  IonSlide,
  IonSlides,
} from '@ionic/react';

import '../theme/intro.css';

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0,
  speed: 400
};

let swiper: any = null;

const init = async function(this: any) {
    swiper = await this.getSwiper();
};

const handleSlideReachEnd = () => localStorage.setItem('intro', 'reachEnd');

const Intro: React.FC<{setShowIntro: Function }> = ({ setShowIntro }) => (
  <IonContent>
    <IonSlides
      pager={true}
      options={slideOpts}
      onIonSlideReachEnd={handleSlideReachEnd}
      onIonSlidesDidLoad={init}
      className='intro'
    >
      <IonSlide style={{ background: 'none' }}>
        <div>
          <img src="assets/LOVE-logo-name.png" alt='L.O.V.E.' style={{ padding: '0 3em 2em' }} />
          <IonButton fill='outline' onClick={() => swiper.slideNext()}>Continue</IonButton>
        </div>
      </IonSlide>
      <IonSlide>
        <div>
          <p>
            <b>The L.O.V.E. Mentoring Program</b> supports and empowers young women to
            strive for success, both in school and in life, through mentorship 
            and health education targeted at mental, reproductive and physical 
            health as well as college and career readiness.
          </p>
          <IonButton fill='outline' onClick={() => swiper.slideNext()}>Continue</IonButton>  
        </div>
      </IonSlide>
      <IonSlide>
        <div>
          <p>
            <b>We seek to empower</b> young Latinas and other young women of diverse 
            backgrounds to believe in themselves, to realize their dreams, and to 
            reach their full potential- whatever that may mean for each of them.
          </p>
          <IonButton fill='outline' onClick={() => swiper.slideNext()}>Continue</IonButton>
        </div>
      </IonSlide>
      <IonSlide>
        <div>
          <p>
            <b>Need help?</b> We are here to share reliable resources in the areas of
            mental health, reproductive health, and college access. 
          </p>
          <IonButton fill='outline' onClick={() => swiper.slideNext()}>Continue</IonButton>
        </div>
      </IonSlide>
      <IonSlide>
      <div>
          <p>
            <b>Don't know where to start?</b> Take our short quizzes to discover where 
            you need support. 
          </p>
          <IonButton fill='outline' onClick={() => setShowIntro()}>Continue</IonButton>
        </div>
      </IonSlide>
    </IonSlides>
  </IonContent>
);

export default Intro;