import React, { useState, useEffect } from 'react';
import { 
  IonSearchbar,
  IonChip,
  IonIcon,
  IonLabel,
  IonGrid,
 } from '@ionic/react';
 import {
  checkmarkCircle,
} from 'ionicons/icons';
import { Resource } from '../types';


export const TabSearch: React.FC<{
  resources: Array<Resource>,
  setResources: Function,
}> = ({ resources, setResources }) => {
  const [searchInput, setSearchInput] = useState('')

  const allTags = resources.map(resource => resource.tags)
  const tags = new Set(allTags.flat())
  
  useEffect(() => {
    if (searchInput) {
      setResources(resources.filter(resource => {
        const matchTitle = resource.title.includes(searchInput)
        const matchUrl = resource.url?.includes(searchInput)
        const matchDesc = resource.description.includes(searchInput)
        const matchTag = resource.tags.includes(searchInput)
        return matchDesc || matchTag || matchTitle || matchUrl
      }))
    } else {
      setResources(resources)
    }
  }, [resources, searchInput, setResources])

  return (
    <>
      <IonSearchbar
        value={searchInput}
        onIonChange={(event) => setSearchInput(event.detail.value || '')} 
      />
      <IonGrid style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
        {Array.from(tags).map(tag => (
          <IonChip onClick={() => setSearchInput(tag)} key={tag}>
            {tag === searchInput && <IonIcon icon={checkmarkCircle} />}
            <IonLabel>{tag}</IonLabel>
          </IonChip>
        ))}
      </IonGrid>
    </>
  )
};

export default TabSearch