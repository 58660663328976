import React from 'react';
import {
  IonButton,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonSlide,
  IonSlides,
  IonText,
} from '@ionic/react';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
} from 'ionicons/icons';
import Topic from '../types'


let swiper: any = null;

const init = async function(this: any) {
    swiper = await this.getSwiper();
};

const QuizResults: React.FC<{
  topic: Topic,
  answers: string[]
}> = ({ topic, answers }) => (
  <IonSlides
    onIonSlidesDidLoad={init}
    pager={true}
  >
    {topic.quiz.map((item, quizIndex) => (
      <IonSlide key={quizIndex} style={{ display: 'block' }}>
        
        <IonItem lines='none'>
          <IonText>
            <h6>{quizIndex + 1} / {topic.quiz.length}</h6>
            <h3>{item.question}</h3>
          </IonText>
        </IonItem>
        
        {item.choices.map((choice, choicesIndex) => {
          const isRightAnswer = choice === item.answer
          const isChosenAnswer = choice === answers[quizIndex]
          
          if (!isRightAnswer && !isChosenAnswer && item.answer !== "All of the above") {
            return null
          }

          return (
            <IonCard>
              <IonItem lines='full' key={choicesIndex} className={topic.key}>
                <IonIcon
                  color={isRightAnswer ? 'success' : 'danger'}
                  icon={isRightAnswer ? checkmarkCircleOutline : closeCircleOutline}
                ></IonIcon>
                <IonLabel className="ion-text-wrap">{choice}</IonLabel>
              </IonItem>
            </IonCard>
          )}
        )}

        <IonCard>
          <IonItem detail={true} lines='full' target='_blank' color='light'
            href={item.resource.url} 
          >
            <IonLabel className="ion-text-wrap">
              <h6><b>Find Support</b></h6>
              {item.resource.description}
            </IonLabel>
          </IonItem>
        </IonCard>
        {quizIndex === topic.quiz.length - 1
          ? null
          : <IonButton
              expand='block' 
              fill='outline'
              style={{ margin: '2em 1em 3em' }}
              onClick={() => swiper.slideNext()}>
              Continue
            </IonButton>}
      </IonSlide>
    ))}
  </IonSlides>
)

export default QuizResults;
