import React from 'react';
import { IonMenu, IonContent, IonList, IonItem } from '@ionic/react';

const resetIntro = () => localStorage.setItem('intro', '');

export const AppMenu: React.FC = () => (
  <IonMenu contentId='menuContentForDragEvents'>
    <IonContent>
      <IonList>
        <IonItem lines='none' href='/' onClick={() => resetIntro()}>About</IonItem>
        <IonItem lines='none' href='http://www.lovementoring.org' target='_blank'>Website</IonItem>
      </IonList>
    </IonContent>
  </IonMenu>
);

export default AppMenu