import React from 'react';
import {
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonButton,
  IonButtons,
} from '@ionic/react';
import {
  helpCircleOutline,
  playCircleOutline
} from 'ionicons/icons';
import Topic from '../types'
import { createPath } from '../utils';

const AppHeader: React.FC<{ topic: Topic }> = ({ topic }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot='start'>
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonTitle>{topic.title}</IonTitle>
        <IonButtons slot='end'>
          <IonButton routerLink={`${createPath(topic)}/intro`}>
            <IonIcon slot="icon-only" icon={playCircleOutline} />
          </IonButton>
          <IonButton routerLink={`${createPath(topic)}/quiz`}>
            <IonIcon slot="icon-only" icon={helpCircleOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    );
  };
  
  export default AppHeader;
  