import React from 'react';

const NotFound: React.FC = () => (
  <div style={{ textAlign: 'center' }}>
    <img src="assets/LOVE-logo-name.png" alt='L.O.V.E.' style={{ padding: '3em' }} />
    <h2>Page Not Found</h2>
    <p>Sorry, please use the tabs below to navigate.</p>
  </div>
)

export default NotFound
