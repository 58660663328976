import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AppHeader from '../components/AppHeader';
import QuizQuestions from '../components/QuizQuestions';
import QuizResults from '../components/QuizResults';
import Topic from '../types'

import './TopicTab.css';


const QuizTab: React.FC<{ topic: Topic }> = ({ topic }) => {
  const [answers, setAnswers] = useState<string[] | []>([])
  const [isDone, setDone] = useState(false)
  
  return (
    <IonPage>
      <AppHeader topic={topic} />
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size='large'>{topic.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        {isDone
        ? <QuizResults topic={topic} answers={answers} />
        : <QuizQuestions
          topic={topic}
          answers={answers}
          setAnswers={setAnswers}
          setDone={setDone}
        />}
      
      </IonContent>
    </IonPage>
  );
};

export default QuizTab;
