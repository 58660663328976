import React, { useState } from 'react';
import {
  IonButton,
  IonCard,
  IonCol,
  IonIcon,
  IonItem,
  IonGrid,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import {
  ellipseOutline,
  checkmarkCircle,
} from 'ionicons/icons';
import Topic from '../types'


const QuizQuestions: React.FC<{
  topic: Topic,
  answers: string[],
  setAnswers: Function,
  setDone: Function,
}> = ({ topic, answers, setAnswers, setDone }) => {
  const [questionNumber, setQuestionNumber] = useState(0)
  const quizQuestion = topic.quiz[questionNumber]

  return (
    <>
      <IonItem lines='none'>
        <IonText>
          <h6>{questionNumber + 1} / {topic.quiz.length}</h6>
          <h3>{quizQuestion.question}</h3>
        </IonText>
      </IonItem>
      
      {quizQuestion.choices.map((choice, index) => {
        const isSelected = choice === answers[questionNumber]

        return (
          <IonCard>
            <IonItem button detail={false} lines='none' key={index}
              className={topic.key}
              onClick={
                () => {
                  const newAnswers = [...answers]
                  newAnswers[questionNumber] = choice
                  setAnswers(newAnswers)
                }
              }
            >
              <IonIcon icon={
                isSelected ? checkmarkCircle : ellipseOutline
              }></IonIcon>
              <IonLabel className="ion-text-wrap">{choice}</IonLabel>
            </IonItem>
          </IonCard>
        )}
      )}

      <IonGrid>
        <IonRow>
          <IonCol>
            {questionNumber
              ? <IonButton expand='block' fill='outline'
                onClick={
                  () => setQuestionNumber(questionNumber-1)
                }
              >Previous</IonButton>
              : null
            }
          </IonCol>
          <IonCol>
            {questionNumber < topic.quiz.length - 1
              ? <IonButton
                  className={topic.key}
                  expand='block'
                  onClick={
                    () => setQuestionNumber(questionNumber+1)
                  }
                >Next</IonButton>
              : <IonButton
                className={topic.key}
                expand='block'
                onClick={
                  () => setDone(true)
                }
              >Done</IonButton>
            }
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}

export default QuizQuestions;
